<template>
  <div class="sign-up-form">
    <div>Create your admin account</div>
    <DxForm :form-data="signUpData">
      <DxSimpleItem data-field="displayName" />
      <DxSimpleItem data-field="email" />
      <DxSimpleItem
        data-field="inviteCode"
        :editor-options="{ readOnly: true }"
      />
      <DxButtonItem :button-options="submitButtonOptions" />
    </DxForm>
  </div>
</template>

<script>
import { DxForm, DxSimpleItem, DxButtonItem } from "devextreme-vue/form";
import { ref, onMounted } from "vue";
import { getAccessToken } from "../auth";

export default {
  components: {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
  },
  setup() {
    const signUpData = ref({
      email: "",
      displayName: "",
      inviteCode: "",
    });

    const submitButtonOptions = {
      text: "Make Admin Account",
      onClick: () => onFormSubmit(signUpData.value),
    };

    const onFormSubmit = async (formData) => {
      // Handle form submission logic here
      console.log("Form data:", formData);

      const rawFormData = {
        email: formData.email,
        displayName: formData.displayName,
        invitationCode: formData.inviteCode,
      };

      // URL of the Azure Function for creating an admin user
      const createAdminUserFunctionUrl = process.env.VUE_APP_CREATE_ADMIN_USER; // Replace with your Azure Function URL

      try {
        const accessToken = await getAccessToken();
        // Call the Azure Function and pass formData
        const response = await fetch(createAdminUserFunctionUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(rawFormData),
        });

        // Check if the request was successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON response
        const responseData = await response.json();

        // Handle the response from the Azure Function
        console.log("Admin User Creation Response:", responseData);
        // Additional logic to handle success (e.g., display a success message, redirect, etc.)
      } catch (error) {
        // Handle errors from the Azure Function call
        console.error("Error Creating Admin User:", error.message);
        // Additional logic to handle failure (e.g., display an error message, etc.)
      }
    };

    // New function to extract query parameters
    const extractInviteCode = () => {
      const queryParams = new URLSearchParams(window.location.search);
      signUpData.value.inviteCode = queryParams.get("inviteCode") || "";
    };

    // Use onMounted to ensure the DOM is ready
    onMounted(() => {
      extractInviteCode();
    });

    return {
      signUpData,
      submitButtonOptions,
      onFormSubmit,
    };
  },
};
</script>

<style>
.sign-up-form {
  max-width: 400px;
  margin: auto;
  margin-top: 35vh;
}
</style>
