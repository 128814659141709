<template>
  <div>Processing authentication response...</div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { myMSALObj, getTokenClaims } from "../auth";

export default {
  setup() {
    const router = useRouter();

    onMounted(async () => {
      try {
        // Inside the try block of onMounted
        await myMSALObj.handleRedirectPromise();
        const claims = getTokenClaims();
        if (claims && claims["extension_UserType"] === "Admin") {
          router.push("/dashboard");
        } else {
          // Handle unknown user type or missing claim
          throw new Error("Unauthorized");
        }
      } catch (error) {
        console.error("Error processing auth response:", error);
        router.push("/login-form");
      }
    });

    return {};
  },
};
</script>
