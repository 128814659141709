export default [];

const adminMenuItems = [
  {
    text: "Dashboard",
    path: "/dashboard",
    icon: "home",
  },
  {
    text: "User Management",
    icon: "user",
    items: [
      {
        text: "Admins",
        path: "/users/admins",
      },
      {
        text: "Landowners",
        path: "/users/landowners",
      },
      {
        text: "Developers",
        path: "/users/developers",
      },
    ],
  },
  {
    text: "Unit Management",
    icon: "mediumiconslayout",
    items: [
      {
        text: "Pending Approval",
        path: "/units/pending-approval",
      },
      {
        text: "Approved",
        path: "/units/approved",
      },
      {
        text: "Rejected",
        path: "/units/rejected",
      },
      {
        text: "Pending Registration",
        path: "/units/pending-registration",
      },
      {
        text: "Pending Payment",
        path: "/units/pending-payment",
      },
      {
        text: "Purchased",
        path: "/units/purchased",
      },
    ],
  },
  {
    text: "Order Management",
    icon: "product",
    items: [
      {
        text: "Pending Registration",
        path: "/orders/pending-registration",
      },
      {
        text: "Pending Payment",
        path: "/orders/pending-payment",
      },
      {
        text: "Paid",
        path: "/orders/paid",
      },
    ],
  },
  {
    text: "Ledger",
    icon: "event",
    path: "/ledger",
  },
  {
    text: "Data Management",
    icon: "download",
    items: [
      {
        text: "Export Units",
        path: "/data/export-units",
      },
      // {
      //   text: "Bulk Import Units",
      //   path: "/data/import-units",
      // },
    ],
  },
  {
    text: "Credit Prices",
    icon: "money",
    items: [
      {
        text: "Add",
        path: "/credit-prices/upsert",
      },
      {
        text: "View",
        path: "/credit-prices/view",
      },
    ],
  },
];

export { adminMenuItems };
