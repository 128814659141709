// import "devextreme/dist/css/dx.common.css";
// import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import "./themes/generated/terra-theme-2.css";
import { createApp } from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";

import { initializeMsal, myMSALObj } from "./auth";

async function initializeApp() {
  try {
    await initializeMsal();
    await myMSALObj.handleRedirectPromise();

    themes.initialized(() => {
      const app = createApp(App);
      app.use(router);
      app.config.globalProperties.$appInfo = appInfo;
      app.mount("#app");
    });
  } catch (error) {
    console.error("Error during MSAL initialization:", error);
    // Handle error appropriately
  }
}

initializeApp();
