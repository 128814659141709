<template>
  <div class="wrapper" v-if="!loading">
    <!-- <div class="login-form">
      <div class="dx-card content">
        <div class="content-block">Login Page</div>
        <dx-button
          text="Sign In"
          width="100%"
          type="default"
          :on-click="onSignInClick"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
// import DxButton from "devextreme-vue/button";
import { signIn, getTokenClaims, signOutAndRedirect, myMSALObj } from "../auth";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import notify from "devextreme/ui/notify";

export default {
  setup() {
    const router = useRouter();

    const loading = ref(false); // Loading state

    // toaster popup code from devExpress library
    const toasterMessage = (msg, type) => {
      notify(
        {
          message: `${msg}`,
          width: "100",
          position: {
            at: "top",
            my: "top",
            of: ".toaster-popups",
          },
        },
        type,
        1500
      );
    };

    // Check authentication status when the component mounts
    onMounted(async () => {
      loading.value = true;

      const accounts = myMSALObj.getAllAccounts();
      if (accounts.length > 0) {
        const claims = getTokenClaims();
        // console.log("The claims are:", claims);
        if (claims && claims["extension_UserType"] === "Admin") {
          router.push("/dashboard");
        } else {
          toasterMessage(`Unauthorised access`, "warning");
          await signOutAndRedirect(router);
          await signIn();
        }
      } else {
        try {
          await signOutAndRedirect(router);
          await signIn();
          // or "loginRedirect"
          // After successful sign-in, get the token claims
          const claims = getTokenClaims();
          console.log(claims);

          // Check the userType claim
          if (claims && claims["extension_UserType"] === "Admin") {
            // Logic for landowner users
            router.push("/dashboard");
          } else {
            // Handle other cases or unknown userType
            toasterMessage(`Unauthorised access`, "info");
          }
          // Handle login error
        } finally {
          loading.value = false; // Stop loading regardless of login success or failure
        }
      }
    });

    // const onSignInClick = async () => {
    //   loading.value = true; // Start loading
    //   try {
    //     await signOutAndRedirect(router);
    //     await signIn("loginPopup");
    //     // or "loginRedirect"
    //     // After successful sign-in, get the token claims
    //     const claims = getTokenClaims();
    //     console.log(claims);

    //     // Check the userType claim
    //     if (claims && claims["extension_UserType"] === "Admin") {
    //       // Logic for landowner users
    //       router.push("/dashboard");
    //     } else {
    //       // Handle other cases or unknown userType
    //       toasterMessage(`Unauthorised access`, "info");
    //     }
    //     // Handle login error
    //   } finally {
    //     loading.value = false; // Stop loading regardless of login success or failure
    //   }
    // };

    return {
      loading,
      toasterMessage,
    };
  },
  components: {
    // DxButton,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 100% !important;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
  .login-form {
    .link {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      margin-top: 20px;

      a {
        text-decoration: none;
      }
    }
  }
}
</style>
