import { createRouter, createWebHistory } from "vue-router";
import { myMSALObj, getTokenClaims } from "./auth"; // Import the MSAL object
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import AuthResponse from "./views/auth-response";
import loginForm from "./views/login-form";
import Dashboard from "./views/dashboard-page";
import AdminSignup from "./views/sign-up-form.vue";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      path: "/dashboard",
      name: "landowner-dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
    },
    {
      path: "/users",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      // child landowner vue's
      children: [
        {
          path: "admins",
          name: "users-admins",
          component: () =>
            import("@/views/user-management/admin-view-page.vue"),
        },
        {
          path: "developers",
          name: "users-developers",
          component: () =>
            import("@/views/user-management/developer-view-page.vue"),
        },
        {
          path: "developers/:userId",
          name: "developer-details",
          component: () =>
            import("@/views/user-management/developer-details.vue"),
        },
        {
          path: "landowners",
          name: "users-landowners",
          component: () =>
            import("@/views/user-management/landowner-view-page.vue"),
        },
        {
          path: "landowners/:userId",
          name: "landowner-details",
          component: () =>
            import("@/views/user-management/landowner-details.vue"),
        },
      ],
    },
    {
      path: "/units",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      children: [
        {
          path: "pending-approval",
          name: "units-pending-approval",
          component: () =>
            import("@/views/unit-management/pending-approval.vue"),
        },
        {
          path: "approved",
          name: "units-approved",
          component: () => import("@/views/unit-management/approved-units.vue"),
        },
        {
          path: "rejected",
          name: "units-rejected",
          component: () => import("@/views/unit-management/rejected-units.vue"),
        },
        {
          path: "pending-registration",
          name: "units-pending-registration",
          component: () =>
            import("@/views/unit-management/pending-registration.vue"),
        },
        {
          path: "pending-payment",
          name: "units-pending-payment",
          component: () =>
            import("@/views/unit-management/pending-payment.vue"),
        },
        {
          path: "purchased",
          name: "units-purchased",
          component: () =>
            import("@/views/unit-management/purchased-units.vue"),
        },
        {
          path: "pending-unit/:unitId/:uid",
          name: "pending-unit-details",
          component: () =>
            import("@/views/unit-management/pending-unit-details.vue"),
        },
        {
          path: "approved-unit/:unitId/:uid",
          name: "approved-unit-details",
          component: () =>
            import("@/views/unit-management/approved-unit-details.vue"),
        },
        {
          path: "rejected-unit/:unitId/:uid",
          name: "rejected-unit-details",
          component: () =>
            import("@/views/unit-management/rejected-unit-details.vue"),
        },
        {
          path: "pending-registration/:unitId/:uid",
          name: "pending-registration-details",
          component: () =>
            import("@/views/unit-management/pending-registration-details.vue"),
        },
        {
          path: "pending-payment/:unitId/:uid",
          name: "pending-payment-details",
          component: () =>
            import("@/views/unit-management/pending-payment-details.vue"),
        },
        {
          path: "purchased-unit/:unitId/:uid",
          name: "purchased-unit-details",
          component: () =>
            import("@/views/unit-management/purchased-unit-details.vue"),
        },
      ],
    },
    {
      path: "/orders",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      children: [
        {
          path: "pending-registration",
          name: "orders-pending-registration",
          component: () =>
            import("@/views/order-management/pending-registration-orders.vue"),
        },
        {
          path: "pending-payment",
          name: "orders-pending-payement",
          component: () =>
            import("@/views/order-management/pending-payment-orders.vue"),
        },
        {
          path: "paid",
          name: "orders-paid",
          component: () => import("@/views/order-management/paid-orders.vue"),
        },
        {
          path: "pending-registration/:orderId/:developerId",
          name: "pending-registration-order-details",
          component: () =>
            import("@/views/order-management/pending-registration-details.vue"),
        },
        {
          path: "pending-payment/:orderId/:developerId",
          name: "pending-order-details",
          component: () =>
            import("@/views/order-management/pending-order-details.vue"),
        },
        {
          path: "paid/:orderId/:developerId",
          name: "paid-order-details",
          component: () =>
            import("@/views/order-management/paid-order-details.vue"),
        },
      ],
    },
    {
      path: "/ledger",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      component: () => import("@/views/ledger/ledger-logs.vue"),
    },
    {
      path: "/data",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      // child landowner vue's
      children: [
        {
          path: "export-units",
          name: "export-units",
          component: () => import("@/views/data-management/export-units.vue"),
        },
      ],
    },
    {
      path: "/credit-prices",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      children: [
        {
          path: "upsert",
          name: "upsert-credit-prices",
          component: () =>
            import("@/views/gov-credit-prices/upsert-credit-prices.vue"),
        },
        {
          path: "view",
          name: "view-credit-prices",
          component: () =>
            import("@/views/gov-credit-prices/view-credit-prices.vue"),
        },
      ],
    },
    {
      path: "/signup",
      name: "admin-signup",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
      },
      component: AdminSignup,
    },
    // common routes...
    {
      path: "/auth-response",
      name: "auth-response",
      component: AuthResponse,
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      component: Profile,
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
        requiredUserType: "Admin",
      },
      component: Tasks,
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
      },
      component: loginForm,
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description:
          "Please enter the email address that you used to register, and we will send you a link to reset your password via Email.",
      },
      component: loadView("reset-password-form"),
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up",
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password",
      },
      component: loadView("change-password-form"),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/login-form", // Redirect all unrecognized paths to root
    },
  ],
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const accounts = myMSALObj.getAllAccounts();
  const claims = getTokenClaims();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!accounts.length || !isValidClaim(claims, to)) {
      // No account or invalid claim for the route
      next({ name: "login-form" });
    } else {
      // User is authenticated and has valid claim, proceed to route
      next();
    }
  } else {
    // No auth required for the route, proceed
    next();
  }
});

function isValidClaim(claims, route = {}) {
  if (route.meta && route.meta.requiredUserType) {
    return (
      claims && claims["extension_UserType"] === route.meta.requiredUserType
    );
  }
  return true; // Default to true if no specific claim required
}

export default router;
