// auth.js
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./utils/authConfig";

const myMSALObj = new PublicClientApplication(msalConfig);

// Initialize the MSAL instance
async function initializeMsal() {
  await myMSALObj.initialize();
}

function signIn(method) {
  return method === "loginPopup"
    ? myMSALObj.loginPopup(loginRequest)
    : myMSALObj.loginRedirect(loginRequest);
}

function signOut() {
  const currentAccounts = myMSALObj.getAllAccounts();
  if (currentAccounts.length > 0) {
    myMSALObj.logoutRedirect({
      account: currentAccounts[0],
      postLogoutRedirectUri: `${process.env.VUE_APP_DOMAIN_URL}/login-form`, // Redirect URI after logout
    });
  } else {
    console.log("an error occured when signing out");
  }
}

function signOutAndRedirect(router) {
  const currentAccounts = myMSALObj.getAllAccounts();
  if (currentAccounts.length > 0) {
    return myMSALObj
      .logoutRedirect({
        account: currentAccounts[0],
        postLogoutRedirectUri: `${process.env.VUE_APP_DOMAIN_URL}/login-form`,
      })
      .then(() => {
        router.push("/login-form");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  } else {
    router.push("/login-form");
  }
}

function getTokenPopup() {
  return myMSALObj.acquireTokenPopup(loginRequest);
}

function getTokenRedirect() {
  return myMSALObj.acquireTokenRedirect(loginRequest);
}

// New function to get token claims
function getTokenClaims() {
  const accounts = myMSALObj.getAllAccounts();
  if (accounts.length > 0) {
    // Get the ID Token claims
    return accounts[0].idTokenClaims;
  }
  return null;
}

// Function to get user ID
function getUserId() {
  const accounts = myMSALObj.getAllAccounts();
  if (accounts.length > 0) {
    // Return the user ID from the token claims
    return accounts[0].idTokenClaims.sub; // or 'objectId', depending on your token configuration
  }
  return null;
}

// function to get jwt access token
async function getAccessToken() {
  const api_app_id = process.env.VUE_APP_AD_APIM_APP_ID;

  try {
    const account = myMSALObj.getAllAccounts()[0];

    const response = await myMSALObj.acquireTokenSilent({
      account: account,
      scopes: [
        `https://${process.env.VUE_APP_AD_B2C_TENANT_NAME}.onmicrosoft.com/${api_app_id}/access`,
      ],
    });

    return response.accessToken;
  } catch (error) {
    console.error(error);
    // Fallback to interactive method
    try {
      const interactiveResult = await myMSALObj.acquireTokenRedirect(
        loginRequest
      );
      return interactiveResult.accessToken;
    } catch (err) {
      console.error(err);
      // Handle interactive method failure
    }
  }
}

export {
  initializeMsal,
  myMSALObj,
  signIn,
  signOut,
  getTokenPopup,
  getTokenRedirect,
  getTokenClaims,
  signOutAndRedirect,
  getUserId,
  getAccessToken,
};
