<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
        </template>
      </dx-item>

      <dx-item location="before" css-class="header-title dx-toolbar-label">
        <img
          src="../assets/images/terraexchangelogo.png"
          alt="Logo"
          class="header-logo"
        />
      </dx-item>
      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel
                :email="email"
                :menu-items="userMenuItems"
                menu-mode="context"
              />
            </dx-button>
          </div>
        </template>
      </dx-item>

      <template #menuUserItem>
        <user-panel
          :email="email"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
// import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { myMSALObj, signOut } from "../auth"; // Import MSAL object and signOut function
import UserPanel from "./user-panel";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
  },
  setup() {
    // const router = useRouter();
    const email = ref("");

    // Update user email based on MSAL accounts
    onMounted(() => {
      const accounts = myMSALObj.getAllAccounts();
      if (accounts.length > 0) {
        email.value = accounts[0].username; // Use appropriate property for email
      }
    });

    const userMenuItems = [
      // {
      //   text: "Profile",
      //   icon: "user",
      //   onClick: () => router.push("/profile"),
      // },
      {
        text: "Logout",
        icon: "runner",
        onClick: () => {
          signOut();
        },
      },
    ];

    return {
      email,
      userMenuItems,
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}
.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;
  width: 30vw !important; /* Increase width to accommodate longer emails */
  display: flex;
  align-items: center;
  margin-right: 20px; /* Vertical alignment */
}
/* Adjust the user button */
.user-button.authorization {
  width: 100% !important;
  max-width: 30vw !important; /* Adjust maximum width as needed */
}
.dx-toolbar.header-toolbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100px; /* Set the height */
  .screen-x-small & {
    padding: 0 20px;
  }
}
.dx-toolbar-items-container {
  height: 100% !important;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;
  width: 25vw !important;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

.header-logo {
  height: 75px; /* Adjust the height as needed */
  width: auto;
  margin: 0 10px; /* Add some space around the logo */
}
</style>
